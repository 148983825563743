<template>
  <div class="d-flex w-100">
    <TInputSimpleSearch
      placeholder="Goods delivery Id"
      :value.sync="search"
      @update:value="triggerSearch"
      class="w-100 mr-1"
    />
    <SButtonFilter
      :active="!lodash.isEmpty(query)"
      :badge="lodash.size(query)"
      class="ml-auto my-auto"
      @click="show = true"
    />
    <!-- <TButtonClear
      v-if="!lodash.isEmpty(query)"
      class="ml-1 my-auto"
      :variant="''"
      color="danger"
      @click="clearFilter"
    /> -->
    <TModal title="Filter" :show="show" @update:show="show = $event">
      <template #actions>
        <SButtonClearFilter
          @click="clearFilter"
          :disabled="loading || lodash.isEmpty(allQuery)"
        />
        <SButtonSearchFilter :disabled="loading" @click="onFilter" />
      </template>

      <CRow>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectCustomer
            :value.sync="filter.customer"
            noCustomLabel
            noFetchStore
            prependAll
            label="Customer"
            class="w-100 mr-1"
            :prepend="{ id: '', name: $t('All customer') }"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectDeliveryPartner
            label="Partner"
            :value.sync="filter.partner_id"
            prependAll
            noFetchStore
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectDeliveryStatus
            label="Status"
            :value.sync="filter.status_id"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputDateTimeRange
            label="Dates"
            :value.sync="dateRange"
            @update:value="setDateRangeFilter"
          />
        </CCol>
      </CRow>
    </TModal>
  </div>
</template>

<script>
import filter from "../mixins/filter";
export default {
  mixins: [filter],
  data() {
    return {
      // loại trừ params
      blockParams: ["tab", "goods_delivery_id", "action", "warehouse.goods_delivery_boxes-page"],
      // search default field
      searchFilter: "id",
      store: "warehouse.goods_deliveries",
    };
  },
};
</script>
