<template>
  <TModal
    title="Import excel"
    size="lg"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButton
        icon="cil-check"
        content="Confirm"
        @click="confirm"
        :disabled="!items.length"
      />
    </template>
    <TInputFile :value.sync="file" accept=".xlsx" :multiple="false" />
    <TButton
      content="Import"
      icon="cil-plus"
      @click="importData"
      :disabled="!file.length || loading"
      class="my-2 ml-auto d-flex"
    />
    <TTableSimple :fields="fields" :items="items" :loading="loading">
      <template #_="{ index }">
        <td>
          <TButtonRemove @click="removeItem(index)" noConfirm />
        </td>
      </template>
      <template #box_id="{ item }">
        <td>
          <TMessageText
            editable
            :value="item.box_id"
            @change="item.box_id = $event"
          />
        </td>
      </template>
      <template #quantity="{ item }">
        <td>
          <TMessageNumber
            editable
            :value="item.quantity ? item.quantity : 0"
            @change="item.quantity = $event"
          />
        </td>
      </template>
    </TTableSimple>
    <TButton
      content="Remove all"
      icon="cil-x-circle"
      color="danger"
      @click="items = []"
      v-if="items.length"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    goods_delivery_id: {
      type: [String, Number],
      required: false,
    },
    container_id: {
      type: [String, Number],
      required: false,
    },
    type: {
      type: String,
      default: "outbound",
      validator(type) {
        return ["outbound", "outcont"].includes(type);
      },
    },
  },
  data() {
    return {
      file: [],
      items: [],
      fields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
        },
        {
          key: "box_id",
          label: "SKU Id",
        },
        {
          key: "quantity",
          label: "Quantity",
        },
      ],
      loading: false,
      key: "",
    };
  },
  watch: {
    goods_delivery_id() {
      this.resetData();
    },
    container_id() {
      this.resetData();
    },
  },
  methods: {
    importData() {
      this.loading = true;
      this.$tomoni.helper.converts
        .importOutcontContainerPickers({
          file: this.file[0]?.file,
        })
        .then(({ data }) => {
          this.items = data
            .filter((x) => x.box_id)
            .map((i) => {
              return {
                ...i,
                box_id: i?.box_id.toString(),
              };
            });
          this.file = [];
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removeItem(index) {
      this.items.splice(index, 1);
    },
    resetData() {
      this.items = [];
      this.file = [];
    },
    confirm() {
      let data = {
        goods_delivery_id: this.goods_delivery_id,
        container_id: this.container_id,
      };
      this.items.forEach((box, index) => {
        const item = {
          id: box.box_id,
          quantity: box.quantity,
        };
        const boxItem = this.lodash.mapKeys(
          this.lodash.pickBy(item),
          (value, key) => {
            return `boxes[${index}][${key}]`;
          }
        );
        data = { ...data, ...boxItem };
      });
      const store =
        this.type == "outcont" ? "out_container_pickers" : "outbound_pickers";
      this.$store
        .dispatch(
          "warehouse." + store + ".create",
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then(() => {
          this.items = [];
          this.$emit("updated");
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
